define("discourse/plugins/discourse-restrict-answers/discourse/templates/connectors/topic-title/login-message", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if currentUser}}
  {{else if isPublicCategory}}
  {{else}}
    <div class="container posts">
      <div class="row">
        <p class="alert" style="display:inline-block">Please login to see answers/replies.</p>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "5eSUVnyG",
    "block": "[[[41,[30,0,[\"currentUser\"]],[[],[]],[[[41,[30,0,[\"isPublicCategory\"]],[[],[]],[[[1,\"  \"],[10,0],[14,0,\"container posts\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"alert\"],[14,5,\"display:inline-block\"],[12],[1,\"Please login to see answers/replies.\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[]]],[1,[28,[32,0],[\"[[\\\"The `currentUser` property path was used in the `discourse/plugins/discourse-restrict-answers/discourse/templates/connectors/topic-title/login-message.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentUser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `isPublicCategory` property path was used in the `discourse/plugins/discourse-restrict-answers/discourse/templates/connectors/topic-title/login-message.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.isPublicCategory}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/discourse-restrict-answers/discourse/templates/connectors/topic-title/login-message.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});