define("discourse/plugins/discourse-restrict-answers/discourse/connectors/topic-above-footer-buttons/leader-member-messasge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.set('isPublicCategory', this.model.allowedCategories.includes(this.model.category_id));
      // console.log('topic-above-post-stream:', this.isPublicCategory);
      window.model = this.model;
      window.app = this;
    }
  };
});