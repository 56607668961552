define("discourse/plugins/discourse-restrict-answers/discourse/initializers/topic", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/components/topic-navigation", "discourse/components/topic-category", "@ember/controller", "@ember/object"], function (_exports, _pluginApi, _decorators, _topicNavigation, _topicCategory, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function initializeHideAnswersPlugin(api) {
    var _dec, _obj;
    let currentUser = Discourse.User ? Discourse.User.current() : false;
    let siteCategories = Discourse.__container__.lookup("controller:application").site.categories;
    let allowedCategories = siteCategories ? siteCategories.filter(cat => cat.make_public_enabled).map(cat => cat.id) : [];
    api.modifyClass("model:topic", {
      init() {
        this._super(...arguments);
        this.allowedCategories = allowedCategories;
        // console.log("allowedCategories", this.allowedCategories)
      }
    });
    api.modifyClass("component:topic-category", {
      pluginId: "t-category",
      init() {
        this._super(...arguments);
        this.allowedCategories = allowedCategories;
      }
    });
    api.modifyClass("controller:topic", (_dec = (0, _decorators.default)("model.postStream.posts", "model.postStream.postsWithPlaceholders"), (_obj = {
      pluginId: "t-plugin",
      init() {
        this._super(...arguments);
        if (this.currentUser) {
          this.currentUser.canViewAnswers = userCanViewAnswers(this.currentUser);
        }
      },
      postsToRender(posts, postsWithPlaceholders) {
        let allowedGroups = Discourse?.SiteSettings?.allowed_groups?.split("|").map(g => g.toLowerCase()) ?? [];
        return this.currentUser && this.currentUser.canViewAnswers || allowedCategories?.includes(this.model?.category_id) || allowedGroups?.some(g => this.currentUser?.groups.map(g => g.name.toLowerCase()).includes(g)) ? this.capabilities.isAndroid ? posts : postsWithPlaceholders : this.capabilities.isAndroid ? posts.slice(0, 1) : postsWithPlaceholders.slice(0, 1);
      }
    }, (_applyDecoratedDescriptor(_obj, "postsToRender", [_dec], Object.getOwnPropertyDescriptor(_obj, "postsToRender"), _obj)), _obj)));
    api.modifyClass("component:topic-navigation", {
      pluginId: "t-navigation",
      init() {
        this._super(...arguments);
        let currentCategory = this.parentView ? this.parentView.topic.category_id : false;
        // console.log("component:topic-navigation!",currentCategory);
        let allowedGroups = Discourse?.SiteSettings?.allowed_groups?.split("|").map(g => g?.toLowerCase()) ?? [];
        if (!userCanViewAnswers(currentUser) && !allowedCategories?.includes(currentCategory) && !allowedGroups?.some(g => currentUser?.groups?.map(g => g.name.toLowerCase())?.includes(g))) {
          this.set("canRender", false);
        }
      }
    });
  }
  function userCanViewAnswers() {
    let user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    let allowedGroups = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    if (!user || !allowedGroups) {
      return;
    }
    let user_groups = user?.groups?.map(g => g.name);
    let allowedGroupsList = allowedGroups?.split("|")?.map(g => g?.toLowerCase());
    return user_groups?.some(g => allowedGroupsList?.includes(g?.toLowerCase())) || user?.admin || user?.moderator;
  }
  var _default = _exports.default = {
    name: "restric-answer",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.2", initializeHideAnswersPlugin);
    }
  };
});