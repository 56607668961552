define("discourse/plugins/discourse-restrict-answers/discourse/templates/components/restrict-replies-setting", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field restrict-replies">
    <h3>{{i18n "restricted_replies.title"}}</h3>
    <label>
      {{input type="checkbox" checked=category.custom_fields.make_public_enabled}}
      {{i18n "restricted_replies.for_category"}}
    </label>
  </section>
  */
  {
    "id": "DJ1tC/2m",
    "block": "[[[10,\"section\"],[14,0,\"field restrict-replies\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"restricted_replies.title\"],null]],[13],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"make_public_enabled\"]]]]]],[1,\"\\n    \"],[1,[28,[35,0],[\"restricted_replies.for_category\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `category` property path was used in the `discourse/plugins/discourse-restrict-answers/discourse/templates/components/restrict-replies-setting.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.category}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-restrict-answers/discourse/templates/components/restrict-replies-setting.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});