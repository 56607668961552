define("discourse/plugins/discourse-restrict-answers/discourse/templates/connectors/topic-above-footer-buttons/leader-member-messasge", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if currentUser.canViewAnswers}}{{else if isPublicCategory}}{{else}}
  	<div class="container">
  		<div class="row">
  			<p class="alert" style="display:inline-block">Active member content
  				only.</p>
  		</div>
  	</div>
  {{/if}}
  
  */
  {
    "id": "+YX+X94s",
    "block": "[[[41,[30,0,[\"currentUser\",\"canViewAnswers\"]],[[],[]],[[[41,[30,0,[\"isPublicCategory\"]],[[],[]],[[[1,\"\\n\\t\"],[10,0],[14,0,\"container\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\\t\\t\\t\"],[10,2],[14,0,\"alert\"],[14,5,\"display:inline-block\"],[12],[1,\"Active member content\\n\\t\\t\\t\\tonly.\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]]]],[]]],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `currentUser` property path was used in the `discourse/plugins/discourse-restrict-answers/discourse/templates/connectors/topic-above-footer-buttons/leader-member-messasge.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentUser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `isPublicCategory` property path was used in the `discourse/plugins/discourse-restrict-answers/discourse/templates/connectors/topic-above-footer-buttons/leader-member-messasge.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.isPublicCategory}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/discourse-restrict-answers/discourse/templates/connectors/topic-above-footer-buttons/leader-member-messasge.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});